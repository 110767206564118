
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import AppLazyImage from '~/components/UI/AppLazyImage.vue'

interface SearchItem {
  id: number
  name: string
  logo: string
  image: string
  description: string
  phone: string
  trainingCourses: string
  createdAt: string
  updatedAt: string
  deletedAt: string | null
  isPublished: number
  budgetAvgScore: string
  paidAvgScore: string
  fullTimePlacesCount: number
  distancePlacesCount: number
}

interface CategoryItem {
  [key: string]: SearchItem
}

@Component({
  components: {
    AppLazyImage
  }
})
export default class extends Vue {
  @Prop({
    default: () => {}
  })
  readonly searchItems!: CategoryItem[]

  @Prop({
    default: false
  })
  readonly isActive!: boolean

  @Prop({
    default: ''
  })
  readonly searchValue!: string

  isFirstSearch = true

  onNonItemClick() {
    this.$emit('on-non-item-click')
  }

  firstThreeElemments(elements) {
    if (elements.length === undefined) {
      const arr: any[] = []
      for (const k in elements) {
        arr.push(elements[k])
      }
      return arr.slice(0, 3)
    } else {
      return elements.slice(0, 3)
    }
  }

  goToLink(key, item) {
    if (key === 'employers') {
      this.$router.push('/employer/' + item.slug)
    } else {
      key !== 'vacancies'
        ? this.$router.push('/' + key + '/' + item.slug)
        : window.open('/' + key + '/' + item.id, '_self')
    }

    this.$emit('on-non-item-click')
  }

  showMatch(value) {
    return value.split(this.searchValue).join(`<strong>${this.searchValue}</strong>`)
  }

  translate(value: string) {
    let res = 'Часто ищут'
    switch (value) {
      case 'news':
        res = 'Новости'
        break
      case 'courses':
        res = 'Курсы'
        break
      case 'books':
        res = 'Книги'
        break
      case 'universities':
        res = 'Университеты'
        break
      case 'employers':
        res = 'Компании'
        break
      case 'colleges':
        res = 'Колледжи'
        break
      case 'professional-education':
        res = 'Дополнительное образование'
        break
      case 'vacancies':
        res = 'Вакансии'
        break
      default:
        break
    }
    return res
  }
}
