
import { Component, Prop, Vue } from 'nuxt-property-decorator'

import HeaderEcosystemMobile from '../Header/HeaderEcosystemMobile.vue'
import BaseMobileBottomSearchMenu from './BaseMobileBottomSearchMenu.vue'

@Component({
  components: {
    BaseMobileBottomSearchMenu,
    HeaderEcosystemMobile
  }
})
export default class extends Vue {
  @Prop({
    default: () => ({})
  })
  readonly menuTabs!: any

  @Prop({
    default: ''
  })
  readonly inputSearchPlaceholder!: string

  @Prop({
    default: () => []
  })
  readonly searchItems!: []

  @Prop({
    default: false
  })
  readonly showNoResults!: boolean

  mobileSidebarMenuVisible = false
  mobileEcosystemMenuVisible = false
  bottomSheetVisible = false
  currentTab? = null
  activeSideMenuTab = ''

  onMenuTabClick(tab) {
    this.currentTab = tab

    if (tab.openMenu) {
      this.mobileSidebarMenuVisible = true
    } else if (tab.openServices) {
      this.mobileEcosystemMenuVisible = !this.mobileEcosystemMenuVisible
    } else {
      this.$emit('on-menu-tab-click', tab)
    }
  }

  toggleMobileMenu() {
    this.mobileSidebarMenuVisible = !this.mobileSidebarMenuVisible
  }

  closeBottomSheet() {
    this.bottomSheetVisible = !this.bottomSheetVisible
    this.$emit('close')
  }

  serviceMenuClick(ref) {
    this.activeSideMenuTab = ref
    const isHttps = ref.includes('https')
    isHttps ? window.open(ref, '_blank') : this.$router.push(`/${ref}`)

    this.toggleMobileMenu()

    this.$emit('service-menu-click', ref)
  }

  onSearchClick() {
    this.bottomSheetVisible = true

    this.$emit('on-search-click')
  }

  onSearch(val) {
    this.$emit('on-search', val)
  }

  onUpdateSearchValue(val) {
    this.$emit('update:value', val)
  }

  onInputClear() {
    this.$emit('clear')
  }

  onCloseEcosystem() {
    this.mobileEcosystemMenuVisible = !this.mobileEcosystemMenuVisible
  }
}
