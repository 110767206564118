
import { Vue, Component, Prop } from 'nuxt-property-decorator'

@Component
export default class extends Vue {
  @Prop({
    default: false
  })
  readonly visible!: boolean
}
