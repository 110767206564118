
import { Component, Prop, Vue, Watch } from 'nuxt-property-decorator'

import SvoiIcon from '@svoi-ui/components/icon/SvoiIcon.vue'
import BottomSheet from './BottomSheet.vue'
import SearchComponent from '~/components/Misc/SearchComponent.vue'

interface SearchItem {
  id: number
  name: string
  logo: string
  image: string
  description: string
  phone: string
  trainingCourses: string
  createdAt: string
  updatedAt: string
  deletedAt: string | null
  isPublished: number
  budgetAvgScore: string
  paidAvgScore: string
  fullTimePlacesCount: number
  distancePlacesCount: number
}

interface CategoryItem {
  [key: string]: SearchItem
}

@Component({
  components: { SvoiIcon, SearchComponent, BottomSheet }
})
export default class extends Vue {
  @Prop({
    default: () => []
  })
  readonly searchItems!: CategoryItem[]

  @Prop({
    default: false
  })
  readonly isVisible!: boolean

  @Prop({
    default: ''
  })
  readonly inputSearchPlaceholder!: string

  @Prop({
    default: false
  })
  readonly showNoResults!: boolean

  searchValue = ''

  get visible() {
    return {
      clearButton: this.searchValue.length
    }
  }

  onCloseSheet() {
    this.$emit('close')
  }

  onUpdateSearchValue(val) {
    this.searchValue = val

    this.$emit('update:search-value', this.searchValue)
  }

  onSearch(val) {
    this.$emit('on-search', val)
  }

  clearValue() {
    this.searchValue = ''

    this.$emit('input-clear')

    this.onUpdateSearchValue('')
  }

  onItemClick() {
    this.onCloseSheet()
  }

  @Watch('isVisible', { immediate: true })
  onIsVisibleChange() {
    this.toggleScroll(this.isVisible ? 'hidden' : 'auto')
  }

  toggleScroll(value: 'hidden' | 'auto'): void {
    if (process.browser) {
      window.document.body.style.overflow = value
    }
  }
}
